import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import $ from "jquery";
import getBrowserFingerprint from 'get-browser-fingerprint';

const LanguageSelector = () => {
	const fingerprint = getBrowserFingerprint();
    const { i18n } = useTranslation(['Nav']);
    const [toggle, setToggle] = useState("");
    const [language, setLanguage] = useState((localStorage.getItem('lanName'))?localStorage.getItem('lanName'):"English");
    const [languages, setLanguages] = useState("");
    const [locale, setLocale] = useState((localStorage.getItem('locale'))?localStorage.getItem('locale'):"en-gb");
    const changeLanguage = (event) => {

      
    
        if(event.languageCode=='ja'){
            // $('#dg_footer_wrapper_inner .dg_footer_item_last img').attr('style','width:20px !important');
            // $('#dg_footer_wrapper_inner #dg_SITE_NOTICE').attr('style','margin-left:24px !important');
            $('#footer').addClass('japana-footer-style');
        }else{
            // $('#dg_footer_wrapper_inner .dg_footer_item_last img').removeAttr('style');
            // $('#dg_footer_wrapper_inner #dg_SITE_NOTICE').removeAttr('style');
            $('#footer').removeClass('japana-footer-style');
        }

		const existinglangCode = localStorage.getItem('lanCode');
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "customEvent",
            eventCategory: "Button",
            eventAction: "Click",  
            eventLabel: "LangSelector",
            eventValue: event.LanguageName, 
            Existinglang: localStorage.getItem('lanName'),
  
          });
          
        $(document.getElementById("navbar-content")).removeClass("show");
        $(document.getElementById("nav-icon4")).removeClass("open");
        i18n.changeLanguage(event.languageCode);
        setToggle(!toggle)
        setLanguage(event.LanguageName)
        setLocale(event.locale)
        localStorage.setItem('lanCode', event.languageCode);
        localStorage.setItem('lanName', event.LanguageName);
        localStorage.setItem('lanId', event.idLanguageMaster);
        localStorage.setItem('locale', event.locale);
		
		if(fingerprint+'P4'=== window.atob(localStorage.getItem('PID')))
                {   
                    localStorage.removeItem("page");
                    localStorage.removeItem("plain_text_BPA_CV");
                    localStorage.setItem("PID", window.btoa(fingerprint + "P2"));
                    localStorage.removeItem("plain_text_NAME_CV");
                    localStorage.removeItem("cMds");
                    localStorage.removeItem("plain_text_DATE_CV");
                    localStorage.removeItem("plain_text_CSN_CV");
                    window.location.reload()
                }
		 if(fingerprint+'P2'=== window.atob(localStorage.getItem('PID')))
                {   
                    if(existinglangCode === 'th'){
                    window.location.reload(false);
                    }
                }

                document.querySelectorAll("#footer li a").forEach(a => {
                    const href = a.getAttribute("href");
                    //console.log(href);
                    if (!href || /^#/.test(href)) a.closest("li").remove();
                });


    
                setTimeout(function(){
                    document.querySelectorAll("#footer li a").forEach((a) => {
                      const href = a.getAttribute("href");
                      //console.log(href);
                      if (!href || /^#/.test(href)) a.closest("li").remove();
                    });
            },400);
        
            setTimeout(function(){
                    document.querySelectorAll("#footer li a").forEach((a) => {
                      const href = a.getAttribute("href");
                      //console.log(href);
                      if (!href || /^#/.test(href)) a.closest("li").remove();
                    });
            },800);
            setTimeout(function(){
                    document.querySelectorAll("#footer li a").forEach((a) => {
                      const href = a.getAttribute("href");
                      //console.log(href);
                      if (!href || /^#/.test(href)) a.closest("li").remove();
                    });
            },1200);

    };
    const getlanguages = async (e) => {
        try {
            const dblanguages = await  axios.get(process.env.REACT_APP_API_ENDPOINT +'/getlanguages');
            

            document.querySelectorAll("#footer li a").forEach(a => {
                const href = a.getAttribute("href");
                //console.log(href);
                if (!href || /^#/.test(href)) a.closest("li").remove();
            });

            if(dblanguages){
                setLanguages(dblanguages.data)
                const userLanguage = window.navigator.userLanguage || window.navigator.language;
                {Object.values(dblanguages.data).map((item) =>
                    {  
                        if(item.languageCode === userLanguage){ 
                                setLanguage(item.LanguageName)
                                localStorage.setItem('lanCode', item.languageCode)
                                localStorage.setItem('lanName', item.LanguageName)
                                localStorage.setItem('lanId', item.idLanguageMaster)
                                localStorage.setItem('locale', item.locale);


                                if(item.languageCode=='ja'){
                                    
                                    // $('#dg_footer_wrapper_inner .dg_footer_item_last img').attr('style','width:20px !important');
                                    // $('#dg_footer_wrapper_inner #dg_SITE_NOTICE').attr('style','margin-left:24px !important');

                                    $('#footer').addClass('japana-footer-style');
                                }else{
                                    // $('#dg_footer_wrapper_inner .dg_footer_item_last img').removeAttr('style');
                                    // $('#dg_footer_wrapper_inner #dg_SITE_NOTICE').removeAttr('style');
                                    $('#footer').removeClass('japana-footer-style');
                                }
                        }
                }
               )}

            }

            
        setTimeout(function(){
            document.querySelectorAll("#footer li a").forEach((a) => {
              const href = a.getAttribute("href");
              //console.log(href);
              if (!href || /^#/.test(href)) a.closest("li").remove();
            });
    },400);

    setTimeout(function(){
            document.querySelectorAll("#footer li a").forEach((a) => {
              const href = a.getAttribute("href");
              //console.log(href);
              if (!href || /^#/.test(href)) a.closest("li").remove();
            });
    },800);
    setTimeout(function(){
            document.querySelectorAll("#footer li a").forEach((a) => {
              const href = a.getAttribute("href");
              //console.log(href);
              if (!href || /^#/.test(href)) a.closest("li").remove();
            });
    },1200);
        } catch (error) {
            if (error.response) {
                setTimeout(() => getlanguages(), 4000);
            }
        }
    }

    useEffect(() => {

        $('head').append('<style id="japana-footer-style">.japana-footer-style #dg_footer_wrapper_inner .dg_footer_item_last{    margin-top: 30px;;clear: both;display: block;}.japana-footer-style #dg_footer_wrapper_inner .dg_footer_item_last img{width:20px !important;display: inline-block;vertical-align: middle;margin-right: 5px;} .japana-footer-style #dg_footer_wrapper_inner #dg_SITE_NOTICE{/*margin-left:24px !important*/}</style>');
        // $('#dg_footer_wrapper_inner .dg_footer_item_last img').removeAttr('style');
        // $('#dg_footer_wrapper_inner #dg_SITE_NOTICE').removeAttr('style');
    

        if(localStorage.getItem('lanCode') && localStorage.getItem('lanCode')=='ja'){
                                    
            // $('#dg_footer_wrapper_inner .dg_footer_item_last img').attr('style','width:20px !important');
            // $('#dg_footer_wrapper_inner #dg_SITE_NOTICE').attr('style','margin-left:24px !important');

            $('#footer').addClass('japana-footer-style');
        }else{
            // $('#dg_footer_wrapper_inner .dg_footer_item_last img').removeAttr('style');
            // $('#dg_footer_wrapper_inner #dg_SITE_NOTICE').removeAttr('style');
            $('#footer').removeClass('japana-footer-style');
        }

        const script = document.createElement('script');
        script.textContent = `dg_locale="${locale}";`;
        script.type = "text/javascript";
        script.async = true;
        document.body.appendChild(script);

        const scriptMaster = document.createElement('script');
        scriptMaster.src = "https://footer.diageohorizon.com/dfs/master.js";
        script.async = true;
        document.body.appendChild(scriptMaster);

        document.querySelectorAll("#footer li a").forEach(a => {
            const href = a.getAttribute("href");
            //console.log(href);
            if (!href || /^#/.test(href)) a.closest("li").remove();
        });


        setTimeout(function(){
                document.querySelectorAll("#footer li a").forEach((a) => {
                  const href = a.getAttribute("href");
                  //console.log(href);
                  if (!href || /^#/.test(href)) a.closest("li").remove();
                });
        },400);

        setTimeout(function(){
                document.querySelectorAll("#footer li a").forEach((a) => {
                  const href = a.getAttribute("href");
                  //console.log(href);
                  if (!href || /^#/.test(href)) a.closest("li").remove();
                });
        },800);
        setTimeout(function(){
                document.querySelectorAll("#footer li a").forEach((a) => {
                  const href = a.getAttribute("href");
                  //console.log(href);
                  if (!href || /^#/.test(href)) a.closest("li").remove();
                });
        },1200);

      return () => {
          document.body.removeChild(script);
          document.body.removeChild(scriptMaster);
        }
      }, [locale]);

        useEffect(() => {
            if(!localStorage.getItem('lanCode')){
                setLanguage("English")
                localStorage.setItem('lanCode', "en")
                localStorage.setItem('lanName', "English")
                localStorage.setItem('lanId', 1)


                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "customEvent",
                  eventCategory: "Button",
                  eventAction: "Click",
                  eventLabel: "LangSelector",
                  eventValue: "English",
                });

                     // $('#dg_footer_wrapper_inner .dg_footer_item_last img').removeAttr('style');
                    // $('#dg_footer_wrapper_inner #dg_SITE_NOTICE').removeAttr('style');
                    $('#footer').removeClass('japana-footer-style');
                


            }
            getlanguages();

            document.querySelectorAll("#footer li a").forEach(a => {
                const href = a.getAttribute("href");
                //console.log(href);
                if (!href || /^#/.test(href)) a.closest("li").remove();
            });

            
        setTimeout(function(){
            document.querySelectorAll("#footer li a").forEach((a) => {
              const href = a.getAttribute("href");
              //console.log(href);
              if (!href || /^#/.test(href)) a.closest("li").remove();
            });
    },400);

    setTimeout(function(){
            document.querySelectorAll("#footer li a").forEach((a) => {
              const href = a.getAttribute("href");
              //console.log(href);
              if (!href || /^#/.test(href)) a.closest("li").remove();
            });
    },800);
    setTimeout(function(){
            document.querySelectorAll("#footer li a").forEach((a) => {
              const href = a.getAttribute("href");
              //console.log(href);
              if (!href || /^#/.test(href)) a.closest("li").remove();
            });
    },1200);

        }, []);

    return (
        <li className="nav-item dropdown dropdown-mega position-static"> <a className="nav-link dropdown-toggle  languagebox" data-bs-toggle="dropdown" data-bs-auto-close="outside">{language}</a>
            <div className={`dropdown-menu shadow ${toggle}`}>
                <div className="mega-content px-5">
                    <div className="container">
                        
                        <div className="col-md-5">

                                <ul className="row">
                                    {Object.values(languages).map((item) =>
                                        <li aria-label={item.LanguageName} key={item.languageCode} onClick={() => changeLanguage(item)} className={`nav-item col-sm-4 ${(item.LanguageName === language)?"active":""}`}>{item.LanguageName}</li>
                                )}
                                </ul>

                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
};
export default LanguageSelector;